import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

const compatibleInstitutions = [
    {
        "name": "Akademia Sztuki Wojennej",
        "domain": "akademia.elearning.wp.mil.pl",
        "tag": "ASzWoj"
    },
    {
        "name": "Politechnika Białostocka, Wydział Informatyki",
        "domain": "cez.wi.pb.edu.pl",
        "tag": "PB WI"
    },
    {
        "name": "Politechnika Łódzka, Centrum Językowe",
        "domain": "cj.edu.p.lodz.pl",
        "tag": "PŁ CJ"
    },
    {
        "name": "Politechnika Łódzka, Wydział Mechaniczny",
        "domain": "cmf.edu.p.lodz.pl",
        "tag": "PŁ WM"
    },
    {
        "name": "Politechnika Krakowska",
        "domain": "delta.pk.edu.pl",
        "tag": "PK"
    },
    {
        "name": "Wyższa Szkoła Ekonomii i Informatyki w Krakowie",
        "domain": "dl.wsei.pl",
        "tag": "WSEI"
    },
    {
        "name": "Zachodniopomorski Uniwersytet Technologiczny w Szczecinie",
        "domain": "e-edukacja.zut.edu.pl",
        "tag": "ZUT"
    },
    {
        "name": "Platforma komercyjna (brak uczelni)",
        "domain": "e-kurs24.pl",
        "tag": ""
    },
    {
        "name": "Niepubliczna uczelnia medyczna (np. Collegium Medicum)",
        "domain": "e-learning.uczelniamedyczna.com.pl",
        "tag": ""
    },
    {
        "name": "Uniwersytet Marii Curie-Skłodowskiej w Lublinie",
        "domain": "e-learning.umlub.pl",
        "tag": "UMCS"
    },
    {
        "name": "Wojskowa Akademia Techniczna",
        "domain": "e-learning.wat.edu.pl",
        "tag": "WAT"
    },
    {
        "name": "Zespół Szkół Ekonomicznych i Ogólnokształcących (szkoła średnia)",
        "domain": "e-learning.zseio.net.pl",
        "tag": "ZSEiO"
    },
    {
        "name": "Uniwersytet Ekonomiczny w Krakowie",
        "domain": "e-uczelnia.uek.krakow.pl",
        "tag": "UEK"
    },
    {
        "name": "Wyższa Szkoła Administracji i Biznesu w Gdyni",
        "domain": "e-wsaib.pl",
        "tag": "WSAiB"
    },
    {
        "name": "Katolicki Uniwersytet Lubelski",
        "domain": "e.kul.pl",
        "tag": "KUL"
    },
    {
        "name": "Pomorski Uniwersytet Medyczny w Szczecinie",
        "domain": "e.pm.szczecin.pl",
        "tag": "PUM"
    },
    {
        "name": "Uniwersytet Kardynała Stefana Wyszyńskiego w Warszawie",
        "domain": "e.uksw.edu.pl",
        "tag": "UKSW"
    },
    {
        "name": "Wyższa Szkoła Ekonomii i Informatyki w Krakowie",
        "domain": "e.wsei.edu.pl",
        "tag": "WSEI"
    },
    {
        "name": "Warszawski Uniwersytet Medyczny",
        "domain": "e.wumed.edu.pl",
        "tag": "WUM"
    },
    {
        "name": "Kazański Uniwersytet Federalny (Rosja)",
        "domain": "edu.kpfu.ru",
        "tag": "KFU"
    },
    {
        "name": "Politechnika Łódzka",
        "domain": "edu.p.lodz.pl",
        "tag": "PŁ"
    },
    {
        "name": "Politechnika Poznańska",
        "domain": "ekursy.put.poznan.pl",
        "tag": "PP"
    },
    {
        "name": "Wojskowa Akademia Medyczna (obecnie WIML)",
        "domain": "el.wam.edu.pl",
        "tag": "WAM/WIML"
    },
    {
        "name": "Akademia Nauk Stosowanych w Łomży",
        "domain": "elearning.ansl.edu.pl",
        "tag": "ANSŁ"
    },
    {
        "name": "Akademia Polonijna w Częstochowie",
        "domain": "elearning.apoz.edu.pl",
        "tag": "AP"
    },
    {
        "name": "Państwowa Akademia Nauk Stosowanych w Nysie",
        "domain": "elearning.pans.nysa.pl",
        "tag": "PANS Nysa"
    },
    {
        "name": "Politechnika Opolska",
        "domain": "elearning.po.edu.pl",
        "tag": "PO"
    },
    {
        "name": "Szkoła im. Mydlowskiej (szkoła średnia)",
        "domain": "elearning.szkolymydlowskiej.pl",
        "tag": ""
    },
    {
        "name": "Politechnika Gdańska",
        "domain": "enauczanie.pg.edu.pl",
        "tag": "PG"
    },
    {
        "name": "Uniwersytet Jana Długosza w Częstochowie",
        "domain": "enauka.ujd.edu.pl",
        "tag": "UJD"
    },
    {
        "name": "Akademia Nauk Stosowanych w Bielsku-Białej",
        "domain": "eportal.ansbb.edu.pl",
        "tag": "ANSBB"
    },
    {
        "name": "Politechnika Wrocławska",
        "domain": "eportal.pwr.edu.pl",
        "tag": "PWr"
    },
    {
        "name": "Uniwersytet Ekonomiczny we Wrocławiu",
        "domain": "eportal.ue.wroc.pl",
        "tag": "UEW"
    },
    {
        "name": "Zespół Szkół Nr 4 (szkoła średnia)",
        "domain": "eszkola.zsnr4.net",
        "tag": ""
    },
    {
        "name": "Uniwersytet Rolniczy w Krakowie",
        "domain": "eureka.urk.edu.pl",
        "tag": "URK"
    },
    {
        "name": "Uniwersytet Warszawski, Centrum Kształcenia Nauczycieli",
        "domain": "kampus-jezyki.ckc.uw.edu.pl",
        "tag": "UW CKC"
    },
    {
        "name": "Uniwersytet Warszawski",
        "domain": "kampus-kursy.ckc.uw.edu.pl",
        "tag": "UW"
    },
    {
        "name": "Uniwersytet Marii Curie-Skłodowskiej w Lublinie",
        "domain": "kampus.umcs.pl",
        "tag": "UMCS"
    },
    {
        "name": "Politechnika Warszawska",
        "domain": "leon.pw.edu.pl",
        "tag": "PW"
    },
    {
        "name": "Uniwersytet Gdański",
        "domain": "mdl.ug.edu.pl",
        "tag": "UG"
    },
    {
        "name": "Akademia Nauk Stosowanych w Elblągu",
        "domain": "moodle.ans-elblag.pl",
        "tag": "ANSE"
    },
    {
        "name": "Akademia Wychowania Fizycznego we Wrocławiu",
        "domain": "moodle.awf.wroc.pl",
        "tag": "AWF Wrocław"
    },
    {
        "name": "Uniwersytet Opolski",
        "domain": "moodle.cs.uni.opole.pl",
        "tag": "UO"
    },
    {
        "name": "Wyższa Szkoła Humanitas w Sosnowcu",
        "domain": "moodle.humanitas.edu.pl",
        "tag": "Humanitas"
    },
    {
        "name": "Uniwersytet Ekonomiczny w Katowicach",
        "domain": "moodle.ue.katowice.pl",
        "tag": "UE Katowice"
    },
    {
        "name": "Uniwersytet Ekonomiczny w Poznaniu",
        "domain": "moodle.ue.poznan.pl",
        "tag": "UEP"
    },
    {
        "name": "Uniwersytet Mikołaja Kopernika w Toruniu",
        "domain": "moodle.umk.pl",
        "tag": "UMK"
    },
    {
        "name": "Uniwersytet Łódzki",
        "domain": "moodle.uni.lodz.pl",
        "tag": "UŁ"
    },
    {
        "name": "Uniwersytet Kaliski (dawna PWSZ)",
        "domain": "moodle.uniwersytetkaliski.edu.pl",
        "tag": "UK"
    },
    {
        "name": "Platforma szkoleniowa (brak danych o instytucji)",
        "domain": "moodle.upelator.pl",
        "tag": ""
    },
    {
        "name": "Wyższa Szkoła Informatyki Stosowanej w Warszawie",
        "domain": "moodle.wsiz.edu.pl",
        "tag": "WSiZ"
    },
    {
        "name": "Wyższa Warszawska Szkoła Informatyki",
        "domain": "moodle.wwsi.edu.pl",
        "tag": "WWSI"
    },
    {
        "name": "Zespół Szkół Nr 1 w Lublinie (szkoła średnia)",
        "domain": "moodle.zs1.lublin.eu",
        "tag": ""
    },
    {
        "name": "Uniwersytet Pedagogiczny w Krakowie",
        "domain": "moodle1.up.krakow.pl",
        "tag": "UP Kraków"
    },
    {
        "name": "WSB Merito",
        "domain": "moodle2.e-wsb.pl",
        "tag": "WSB"
    },
    {
        "name": "Akademia WSB",
        "domain": "online.wsb.edu.pl",
        "tag": "Awsb"
    },
    {
        "name": "Wyższa Szkoła Gospodarki w Bydgoszczy",
        "domain": "onte.wsg.byd.pl",
        "tag": "WSG"
    },
    {
        "name": "Uniwersytet Jagielloński w Krakowie",
        "domain": "pegaz.uj.edu.pl",
        "tag": "UJ"
    },
    {
        "name": "Politechnika Śląska",
        "domain": "platforma.polsl.pl",
        "tag": "PŚ"
    },
    {
        "name": "Platforma zewnętrzna (brak danych o instytucji)",
        "domain": "ptgnet.eu",
        "tag": ""
    },
    {
        "name": "MoodleCloud (zewnętrzna platforma edukacyjna)",
        "domain": "quizonator.moodlecloud.com",
        "tag": "MoodleCloud"
    },
    {
        "name": "MoodleCloud (wersja edukacyjna)",
        "domain": "quizonatoredu.moodlecloud.com",
        "tag": "MoodleCloud"
    },
    {
        "name": "Moodle (środowisko testowe)",
        "domain": "sandbox.moodledemo.net",
        "tag": "MoodleDemo"
    },
    {
        "name": "Moodle (demo dla szkół)",
        "domain": "school.moodledemo.net",
        "tag": "MoodleDemo"
    },
    {
        "name": "Sii Polska (firma szkoleniowa)",
        "domain": "siilearningplatform.sii.pl",
        "tag": "Sii"
    },
    {
        "name": "Gdański Uniwersytet Medyczny",
        "domain": "szkolenia.gumed.edu.pl",
        "tag": "GUMed"
    },
    {
        "name": "Uniwersytet im. Adama Mickiewicza w Poznaniu",
        "domain": "szkoleniebhp.amu.edu.pl",
        "tag": "UAM"
    },
    {
        "name": "Platforma e-learningowa MON, związana z ASzWoj",
        "domain": "szkolenieobronne.elearning.wp.mil.pl",
        "tag": "ASzWoj"
    },
    {
        "name": "Technikum (platforma zewnętrzna dla szkół)",
        "domain": "technikum.gnomio.com",
        "tag": ""
    },
    {
        "name": "Akademia Górniczo-Hutnicza w Krakowie",
        "domain": "upel.agh.edu.pl",
        "tag": "AGH"
    },
    {
        "name": "Politechnika Świętokrzyska w Kielcach",
        "domain": "weaii-moodle.tu.kielce.pl",
        "tag": "PŚk"
    },
    {
        "name": "Szkoła Wyższa im. Pawła Włodkowica w Płocku",
        "domain": "wlodek.wlodkowic.pl",
        "tag": "Włodkowic"
    }


]


export default function CompatibleInstitutionsSection() {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');

    const filteredInstitutions = compatibleInstitutions.filter((inst) => {
        const query = searchQuery.toLowerCase();
        return (
            inst.name.toLowerCase().includes(query) ||
            inst.domain.toLowerCase().includes(query) ||
            inst.tag.toLowerCase().includes(query)
        );
    });

    let displayedInstitutions = [];
    let leftoverCount = 0;

    if (filteredInstitutions.length > 5) {
        displayedInstitutions = filteredInstitutions.slice(0, 5);
        leftoverCount = filteredInstitutions.length - 5;
    } else {
        displayedInstitutions = filteredInstitutions;
    }

    return (
        <section className="py-12 bg-gray-50">
            <div className="container mx-auto px-4 md:px-6">
                {/* Tytuł sekcji */}
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-2 text-[#1B4965]">
                    {t('home.compatibleInstitutions.title')}
                </h2>
                <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
                    {t('home.compatibleInstitutions.description')}
                </p>

                {/* Searchbar */}
                <div className="flex items-center justify-center mb-8">
                    <div className="relative w-full max-w-md">
                        <FaSearch className="absolute left-3 top-3 text-gray-400" />
                        <input
                            type="text"
                            placeholder={t('home.compatibleInstitutions.searchPlaceholder')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1B4965] transition"
                        />
                    </div>
                </div>

                {filteredInstitutions.length === 0 ? (
                    <div className="text-center text-gray-500">
                        {t('home.compatibleInstitutions.noResults')}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {displayedInstitutions.map((inst, index) => (
                            <div
                                key={index}
                                className="p-6 bg-white rounded-xl shadow-lg border border-[#E7F5FF] hover:shadow-xl transition duration-300"
                            >
                                <h3 className="text-xl font-semibold text-[#1B4965] mb-2">
                                    {inst.name}
                                </h3>
                                <p className="text-gray-600">{inst.domain}</p>
                            </div>
                        ))}
                        {leftoverCount > 0 && (
                            <div className="p-6 flex items-center justify-center bg-white rounded-xl shadow-lg border border-[#E7F5FF] hover:shadow-xl transition duration-300 text-center">
                                <span className="text-lg font-semibold text-[#1B4965]">
                                    {t('home.compatibleInstitutions.moreResults', { count: leftoverCount })}
                                </span>
                            </div>
                        )}
                    </div>
                )}

                {/* Disclaimer */}
                <p className="text-xs text-gray-400 text-center mt-8 max-w-2xl mx-auto">
                    {t('home.compatibleInstitutions.disclaimer')}
                </p>
            </div>
        </section>
    );
}
