import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { logEvent } from '../analytics';

const PromoSection = ({ t }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });
    const [isPromoActive, setIsPromoActive] = useState(true);

    useEffect(() => {
        // Ustalamy datę końca promocji:
        // 11 lutego 2025 roku 24:00 to w JavaScript "2025-02-12T00:00:00"
        const targetDate = new Date("2025-02-12T00:00:00");

        const updateCountdown = () => {
            const now = new Date();
            const difference = targetDate.getTime() - now.getTime();

            if (difference <= 0) {
                // Promocja się zakończyła – ukrywamy element
                setIsPromoActive(false);
                return;
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({ days, hours, minutes, seconds });
        };

        updateCountdown();
        const timer = setInterval(updateCountdown, 1000);

        return () => clearInterval(timer);
    }, []);

    // Jeśli promocja już się zakończyła, nie renderujemy żadnego elementu
    if (!isPromoActive) {
        return null;
    }

    return (
        <section className="py-20 bg-gradient-to-r from-[#00D1A7] to-[#2196F3]">
            <div className="container mx-auto px-4 md:px-6 text-center text-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-4">
                    {t("home.promo.title")}
                </h2>
                <p className="text-xl mb-8">
                    {t("home.promo.description")}
                </p>
                <div className="flex justify-center space-x-4 mb-8">
                    {Object.entries(timeLeft).map(([unit, value]) => (
                        <div
                            key={unit}
                            className="flex flex-col items-center p-4 bg-white bg-opacity-20 rounded-lg"
                        >
                            <span className="text-3xl font-bold">
                                {value.toString().padStart(2, "0")}
                            </span>
                            <span className="text-sm uppercase">
                                {t(`home.promo.timeUnits.${unit}`)}
                            </span>
                        </div>
                    ))}
                </div>
                <Link to="/cennik">
                    <Button
                        size="lg"
                        className="text-lg bg-white text-[#1B4965] hover:bg-gray-200"
                        onClick={() => {
                            logEvent("Home", "Click", t("home.promo.buttonText"));
                        }}
                    >
                        {t("home.promo.buttonText")}
                    </Button>
                </Link>
            </div>
        </section>
    );
};

export default PromoSection;
