import React, { useState } from 'react';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';

const NewsletterPopup = ({ showPopup, handleClose, onSubscribe }) => {
    const [email, setEmail] = useState('');
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubscribe(email);
    };

    return showPopup ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl p-8 max-w-md w-full shadow-lg">
                <h3 className="text-2xl font-bold text-[#1B4965] mb-4">{t('popup.newsletterTitle')}</h3>
                <p
                    className="text-gray-600 mb-6"
                    dangerouslySetInnerHTML={{ __html: t('popup.newsletterContent') }}
                ></p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        required
                        placeholder={t('popup.emailPlaceholder')}
                        className="w-full px-4 py-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="flex gap-4">
                        <Button
                            type="submit"
                            className="bg-[#00D1A7] text-white hover:bg-[#00B594] flex-1"
                        >
                            {t('popup.getDiscount')}
                        </Button>
                        <Button
                            type="button"
                            onClick={handleClose}
                            className="bg-gray-100 text-gray-600 hover:bg-gray-200 flex-1"
                        >
                            {t('popup.maybeLater')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    ) : null;
};

export default NewsletterPopup;
