import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { logEvent } from "../analytics"
import { auth } from "../firebase"

import { Button } from "../components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card"
import { CheckCircle, Sparkles } from "lucide-react"
import { Badge } from "../components/ui/badge"
import Tooltip from "../components/ui/tooltip.js"
import { Modal } from "../components/ui/modal"

// --------------------- KOMPONENT PricingCard ---------------------
function PricingCard({ plan, isSemestral, onCtaClick, user, isPromoActive }) {
    const { t } = useTranslation()

    // Sprawdzamy, czy plan jest aktywny u użytkownika
    const hasActivePlan =
        user?.plan === (isSemestral ? "semestral" : "monthly") &&
        user?.subscriptionEndDate &&
        new Date() < new Date(user.subscriptionEndDate)

    // Renderowanie listy feature'ów
    const renderFeatures = () => (
        <ul className="space-y-4">
            {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center gap-3">
                    <CheckCircle className="w-5 h-5 text-[#00D1A7] flex-shrink-0" />
                    <span className="text-gray-700 text-sm sm:text-base">{feature}</span>
                </li>
            ))}
        </ul>
    )

    // Zielona ramka i wyróżnienie badge'a, gdy plan = semestralny
    const semestralBorder = isSemestral
        ? "border-2 border-[#00D1A7] shadow-lg hover:shadow-xl"
        : "bg-white rounded-2xl shadow-lg hover:shadow-xl border border-transparent"

    return (
        <Card
            className={`relative overflow-visible rounded-2xl transition-all duration-300 flex flex-col justify-between h-full ${semestralBorder}`}
        >
            {/* GÓRA KARTY */}
            <div>
                {/* Badge (Oszczędzasz 20%) – tylko jeśli isSemestral i isPromoActive (przed końcem promocji) */}
                {isSemestral && (
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                        <Badge className="bg-[#FF5722] flex text-white text-lg px-8 py-2">
                            <Sparkles className="w-4 h-4 mr-1" />
                            {plan.savings?.badgeText || "Oszczędzasz 20%"}
                        </Badge>
                    </div>
                )}

                {/* Header: Tytuł i opis */}
                <CardHeader className="text-center pt-12">
                    <CardTitle className="text-3xl font-bold text-[#1B4965] mb-2">{plan.planName}</CardTitle>
                    <CardDescription className="text-lg text-gray-600">
                        {t("pricing.card.description")}
                    </CardDescription>
                </CardHeader>

                {/* Cena i ewentualny napis "/ 6 miesięcy" */}
                <CardContent className="space-y-6">
                    <div className="text-center">
                        <div className="flex flex-col items-center gap-2 mb-2">
                            <div className="flex items-center gap-4">
                                <span className="text-5xl font-bold text-gray-500">{plan.price}</span>
                            </div>
                            <div className="flex items-center justify-center gap-2 text-gray-600 text-sm sm:text-base">
                                {isSemestral
                                    ? <span>{t("pricing.semestral.months6Suffix")}</span>
                                    : <span>{t("pricing.monthly.validity")}</span>}
                            </div>

                            {/* Informacja o FORMS20 – tylko gdy plan semestralny i isPromoActive */}
                            {isSemestral && isPromoActive && (
                                <div className="mt-2 text-[#00D1A7] font-medium">
                                    {t("pricing.trial.useCodeForms20Full")}

                                </div>
                            )}
                        </div>
                    </div>

                    {/* Lista feature'ów */}
                    <div>{renderFeatures()}</div>
                </CardContent>
            </div>

            {/* DÓŁ KARTY */}
            <div>
                {/* Disclaimer */}
                <CardFooter className="text-center text-sm text-gray-600">
                    {t("pricing.trial.disclaimer")}
                </CardFooter>

                {/* CTA */}
                <CardFooter className="flex flex-col gap-4 pb-8 px-4">
                    <Button
                        size="lg"
                        className={`w-full py-3 rounded-lg shadow font-semibold ${hasActivePlan
                            ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                            : "bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white hover:from-[#00B594] hover:to-[#008F78]"
                            }`}
                        disabled={hasActivePlan}
                        onClick={!hasActivePlan ? onCtaClick : undefined}
                    >
                        {user
                            ? hasActivePlan
                                ? t("pricing.card.btnActive")
                                : isSemestral
                                    ? t("pricing.trial.btnBuySemester")
                                    : t("pricing.trial.btnBuyMonthly")
                            : t("pricing.trial.btnRegisterWith20Free")}
                    </Button>
                </CardFooter>
            </div>
        </Card>
    )
}

// --------------------- KOMPONENT GŁÓWNY Pricing ---------------------
export default function Pricing() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [user, setUser] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [isPromoActive, setIsPromoActive] = useState(false)

    // Ustal datę zakończenia promocji (np. 11 lutego 2025 23:59)
    const PROMO_END_DATE = new Date("2025-02-11T23:59:00")

    useEffect(() => {
        logEvent("PageView", "Pricing", t("pricing.pageViewLog"))

        // Sprawdzamy, czy obecna data < PROMO_END_DATE
        const now = new Date()
        setIsPromoActive(now < PROMO_END_DATE)

        // Pobieramy dane usera
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser
                if (!currentUser) {
                    setUser(null)
                    return
                }
                const token = await currentUser.getIdToken()
                const response = await fetch(
                    "https://us-central1-quizonator-a4513.cloudfunctions.net/api/getUserDataWithQuizSummaries",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({ email: currentUser.email })
                    }
                )
                if (!response.ok) {
                    throw new Error("Failed to fetch user data")
                }
                const userData = await response.json()
                setUser(userData.userData)
            } catch (error) {
                console.error("Error fetching user data:", error)
                setUser(null)
            }
        }

        fetchUserData()

        // Subskrypcja autoryzacji
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser)
        })
        return () => unsubscribe()
    }, [t])

    // Konfiguracja planów
    const monthlyData = {
        planName: t("pricing.monthly.planName"),
        price: t("pricing.monthly.price"),
        validity: t("pricing.monthly.validity"),
        features: t("pricing.monthly.features", { returnObjects: true })
    }

    const semestralData = {
        planName: t("pricing.semestral.planName"),
        price: t("pricing.semestral.price"),
        validity: t("pricing.semestral.validity"),
        features: t("pricing.semestral.features", { returnObjects: true }),
        savings: t("pricing.semestral.savings", { returnObjects: true })
        // np. { badgeText: "Oszczędzasz 20%", details: "Do 11 lutego 23:59" }
    }

    // Obsługa CTA
    const handleCtaClick = (planType) => {
        if (!user) {
            navigate("/register")
        } else {
            navigate(`/payment?plan=${planType}`)
            logEvent("Button", "Click", t("pricing.card.ctaLog"))
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("pricing.meta.title")}</title>
                <meta name="description" content={t("pricing.meta.description")} />
            </Helmet>

            <div className="relative bg-gray-50">
                <main className="container px-4 sm:px-6 lg:px-8 py-16 md:py-20 lg:py-14 mx-auto">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-[#1B4965] mb-6">
                            {t("pricing.header.title")}
                        </h1>
                        <p className="text-lg md:text-xl text-gray-700 mb-6">
                            {t("pricing.header.description")}
                        </p>

                        {/* Opcjonalnie, wyświetlamy też info "Promocja do 11 lutego 23:59" */}
                        {isPromoActive && (
                            <div className="bg-[#FCEFD3] text-[#1B4965] font-medium px-4 py-2 inline-block rounded-lg">
                                {t("pricing.trial.promoActiveBanner")}
                            </div>
                        )}
                    </div>

                    {/* Pricing Cards */}
                    <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                        {/* Plan Miesięczny */}
                        <PricingCard
                            plan={monthlyData}
                            isSemestral={false}
                            onCtaClick={() => handleCtaClick("monthly")}
                            user={user}
                            isPromoActive={isPromoActive} // przekazujemy, by w razie potrzeby wiedzieć, że jest promo
                        />

                        {/* Plan Semestralny */}
                        <PricingCard
                            plan={semestralData}
                            isSemestral
                            onCtaClick={() => handleCtaClick("semestral")}
                            user={user}
                            isPromoActive={isPromoActive}
                        />
                    </div>

                    {/* Sekcja FAQ */}
                    <div className="mt-20 text-center">
                        <h2 className="text-2xl font-bold text-[#1B4965] mb-4">{t("pricing.faq.title")}</h2>
                        <p className="text-gray-600 mb-6">{t("pricing.faq.description")}</p>
                        <Link to="/faq">
                            <Button
                                variant="outline"
                                className="text-[#1B4965] border-[#1B4965] hover:bg-[#1B4965] hover:text-white transition-all"
                            >
                                {t("pricing.faq.button")}
                            </Button>
                        </Link>
                    </div>
                </main>
            </div>

            {/* Modal - opcjonalnie */}
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto text-center">
                    <button
                        onClick={() => setShowModal(false)}
                        className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
                        aria-label="Close"
                    >
                        ✖
                    </button>
                    <h2 className="text-2xl font-extrabold text-[#1B4965] mb-4">Darmowy dostęp do 24 stycznia!</h2>
                    <p className="text-gray-600 text-lg">
                        Aktualnie obowiązuje darmowy dostęp do 24 stycznia. Po tym terminie obowiązywać będą standardowe ceny.
                    </p>
                    <div className="mt-6">
                        <button
                            onClick={() => setShowModal(false)}
                            className="px-6 py-2 bg-[#00D1A7] text-white text-lg font-semibold rounded-lg hover:bg-[#00B594] transition-all"
                        >
                            Rozumiem
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
