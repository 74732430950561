import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';

const ConfirmationPopup = ({ showPopup, handleClose, onSubscribe }) => {
    const { t } = useTranslation();

    if (!showPopup) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl p-8 max-w-md w-full shadow-lg">
                <h3 className="text-2xl font-bold text-[#1B4965] mb-4">
                    {t('popup.confirmTitle')}
                </h3>
                <p
                    className="text-gray-600 mb-6"
                    dangerouslySetInnerHTML={{ __html: t('popup.confirmContent') }}
                ></p>
                <div className="flex gap-4">
                    <Button
                        onClick={() => {
                            onSubscribe();
                            handleClose();
                        }}
                        className="bg-[#00D1A7] text-white hover:bg-[#00B594] flex-1"
                    >
                        {t('popup.confirmButton')}
                    </Button>
                    <Button
                        onClick={handleClose}
                        className="bg-gray-100 text-gray-600 hover:bg-gray-200 flex-1"
                    >
                        {t('popup.maybeLater')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
