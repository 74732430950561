import React, { useState } from 'react';
import { Button } from '../ui/button';
import { motion, AnimatePresence } from 'framer-motion';

// Warianty animacji dla tła i pudełka
const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const modalVariants = {
    hidden: { opacity: 0, scale: 0.75 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
    exit: { opacity: 0, scale: 0.75 },
};

const ThankYouPopup = ({ show, setShowThankYouPopup }) => {

    return (
        <AnimatePresence>
            {show && (
                <>
                    <motion.div
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
                        variants={backdropVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            className="bg-white rounded-xl p-8 max-w-md w-full shadow-lg text-center relative"
                            variants={modalVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            <h3 className="text-2xl font-bold text-[#1B4965] mb-4">
                                Dziękujemy za zapisanie się! 🎉
                            </h3>
                            <p className="text-gray-600 mb-6">
                                Już wkrótce otrzymasz nasze najlepsze oferty i specjalne promocje.
                            </p>
                            <Button
                                onClick={() => setShowThankYouPopup(false)}
                                className="bg-[#00D1A7] text-white hover:bg-[#00B594]"
                            >
                                Zamknij
                            </Button>
                        </motion.div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default ThankYouPopup;
