import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate, Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import axios from 'axios';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { logEvent } from '../analytics';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function RegisterPage() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);
    const [isSubscribedToNewsletter, setIsSubscribedToNewsletter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [refCode, setRefCode] = useState(null);

    useEffect(() => {
        // Odczytaj refCode z cookies
        const codeFromCookie = Cookies.get('refCode');
        if (codeFromCookie) {
            setRefCode(codeFromCookie);
        }
    }, []);

    async function onSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setMessage('');

        if (!isAgreedToTerms) {
            setError(t('registerPage.termsError'));
            setIsLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError(t('registerPage.passwordMismatch'));
            setIsLoading(false);
            return;
        }

        if (password.length < 6) {
            setError(t('registerPage.passwordLengthError'));
            setIsLoading(false);
            return;
        }

        if (username.trim().length < 3) {
            setError(t('registerPage.usernameLengthError'));
            setIsLoading(false);
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const idToken = await user.getIdToken();

            await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/register',
                { uid: user.uid, email, username, referralCode: refCode },
                { headers: { 'Authorization': `Bearer ${idToken}` } }
            );

            // Zapisz użytkownika do newslettera, jeśli checkbox jest zaznaczony
            if (isSubscribedToNewsletter) {
                try {
                    await axios.post(
                        'https://us-central1-quizonator-a4513.cloudfunctions.net/api/newsletter-subscribe',
                        { email },
                        { headers: { 'Content-Type': 'application/json' } }
                    );
                } catch (newsletterError) {
                    console.error('Newsletter subscription failed:', newsletterError);
                    // Możesz dodać logowanie błędu do systemu analytics
                    logEvent('Error', 'Newsletter Subscribe', newsletterError.message);
                }
            }
            Cookies.remove('refCode');
            setMessage(t('registerPage.successMessage'));
            setIsLoading(false);
            navigate('/dashboard');
            logEvent('User', 'Register', 'Nowy użytkownik');
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.error || t('registerPage.genericError'));
            setIsLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("registerPage.seoTitle")}</title>
                <meta name="description" content={t("registerPage.seoDescription")} />
            </Helmet>

            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
                <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                    <CardHeader className="text-center space-y-1">
                        <CardTitle className="text-3xl font-semibold text-[#1B4965]">
                            {t("registerPage.title")}
                        </CardTitle>
                        <CardDescription className="text-gray-600">
                            {t("registerPage.subtitle")}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="p-6 space-y-6">
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div>
                                <Label htmlFor="username">{t("registerPage.usernameLabel")}</Label>
                                <Input
                                    id="username"
                                    type="text"
                                    placeholder={t("registerPage.usernamePlaceholder")}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="email">{t("registerPage.emailLabel")}</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder={t("registerPage.emailPlaceholder")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="password">{t("registerPage.passwordLabel")}</Label>
                                <Input
                                    id="password"
                                    type="password"
                                    placeholder={t("registerPage.passwordPlaceholder")}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="confirmPassword">{t("registerPage.confirmPasswordLabel")}</Label>
                                <Input
                                    id="confirmPassword"
                                    type="password"
                                    placeholder={t("registerPage.confirmPasswordPlaceholder")}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    type="checkbox"
                                    checked={isAgreedToTerms}
                                    onChange={(e) => setIsAgreedToTerms(e.target.checked)}
                                    required
                                    className="w-5 h-5 accent-green-600 border-gray-300 rounded focus:ring-green-600"
                                />
                                <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
                                    {t("registerPage.termsAgreement")}
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="newsletter"
                                    type="checkbox"
                                    checked={isSubscribedToNewsletter}
                                    onChange={(e) => setIsSubscribedToNewsletter(e.target.checked)}
                                    className="w-5 h-5 accent-green-600 border-gray-300 rounded focus:ring-green-600"
                                />
                                <label htmlFor="newsletter" className="ml-2 text-sm text-gray-700">
                                    {t("registerPage.newsletterAgreement")}
                                </label>
                            </div>
                            {error && <div className="text-red-500 text-sm">{error}</div>}
                            {message && <div className="text-green-500 text-sm">{message}</div>}
                            <Button className="w-full bg-[#00D1A7] hover:bg-[#00B594] text-white" type="submit" disabled={isLoading}>
                                {isLoading ? t("registerPage.loading") : t("registerPage.submitButton")}
                            </Button>
                        </form>
                    </CardContent>
                    <CardFooter className="text-center text-sm text-gray-600">
                        {t("registerPage.agreeTerms")}{" "}
                        <Link to="/warunki-uzytkowania" className="underline text-[#2196F3]">
                            {t("registerPage.termsLink")}
                        </Link>{" "}
                        {t("registerPage.and")}{" "}
                        <Link to="/polityka-prywatnosci" className="underline text-[#2196F3]">
                            {t("registerPage.privacyLink")}
                        </Link>
                    </CardFooter>
                </Card>
                <p className="mt-6 text-center text-sm text-gray-600">
                    {t("registerPage.haveAccount")}{" "}
                    <Link to="/login" className="underline text-[#2196F3] hover:text-[#1B4965]">
                        {t("registerPage.loginLink")}
                    </Link>
                </p>
            </div>
        </>
    );
}